@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans TC", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #e3e3db;
}
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:font-bold:hover {
  font-weight: bold;
}

.mainv2{
  background-color: #e3e3db;
}

.main{
  position: relative;
}

.bgnav{
  background-color: transparent;
}

video{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contentv2{
  position: absolute;
  width: 100%;
  top: 60px;
}

.contentnav{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.overlay{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(227,227,219,.4);
}